const isAppOrigin = (origin, platform = null) => {
  if (platform === 'android') {
    return origin === 'com.vayapin.app.android'
  }

  if (platform === 'ios') {
    return origin === 'com.vayapin.app.ios'
  }

  if (origin === 'com.vayapin.app') return true
  if (origin === 'com.vayapin.app.ios') return true
  if (origin === 'com.vayapin.app.android') return true

  return false
}

export default isAppOrigin
