/**
 * Application context for layout, origin and theme settings.
 */

import { createContext } from 'react'

// Default context structure
export const DEFAULT_CONTEXT_DATA = {
  theme: 'light',
  origin: 'web',
  reducedInterface: false,
}

// Context
const ConfigContext = createContext(DEFAULT_CONTEXT_DATA)
ConfigContext.displayName = 'ConfigContext'

// export provider/consumer
export const Provider = ConfigContext.Provider
export const Consumer = ConfigContext.Consumer

// Export the context
export default ConfigContext
