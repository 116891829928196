import { lazy, Suspense } from 'react'
import LazyLoadingScreen from 'screens/LazyLoadingScreen'

const Screen = lazy(() => import('./AccountTermsScreen'))

const AccountTermsScreenWrapper = () => (
  <Suspense fallback={<LazyLoadingScreen />}>
    <Screen />
  </Suspense>
)

export default AccountTermsScreenWrapper
