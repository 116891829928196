import { Base64 } from 'js-base64'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import without from 'lodash/without'

let _instance = null

export const LOCALSTORAGE_KEY = Base64.encode('vayapin-id-token-auth')


/**
 * Handles the auth token persistance.
 *
 * Token data example:
 *
 * access-token 0KaLUXbTMHZtKY9pkPkXDw
 * access-token-type Bearer
 * access-token-client 2hNMdsY0BhaQP11MdINVQQ
 * access-token-expiry 1582029168
 * access-token-uid dev+admin@hllg.de
 */
class AuthToken {

  /**
   * The singleton Auth instance.
   * @static
   * @return {Auth} Instance
   */
  static get() {
    if (!_instance) { _instance = new this() }
    return _instance
  }

  /**
   * Reset singleton instance. Auth.get() will return a new instance next time called.
   * @static
   */
  static reset() {
    if (_instance) _instance.reset()
    _instance = null
  }

  /**
   * Constructor
   */
  constructor() {
    this.reset()
  }

  /**
   * Loads the token data from localStorage
   * @return {[type]} [description]
   */
  getTokenData() {
    let data = window.localStorage.getItem(LOCALSTORAGE_KEY) || ''
    data = Base64.decode(data) || '{}'

    try {
      data = JSON.parse(data)
    } catch (e) {
      return null
    }

    return isObject(data) && has(data, 'access-token') ? data : null
  }

  /**
   * Update the localStorage with the passed token data
   * @param  {Object} data The token data
   */
  updateTokenData(data) {
    if (!isString(data['access-token']) || data['access-token'].length <= 0) {
      return
    }

    window.localStorage.setItem(
      LOCALSTORAGE_KEY,
      Base64.encode(JSON.stringify(pick(
        data,
        'access-token',
        'access-token-client',
        'access-token-expiry',
        'access-token-type',
        'access-token-uid',
      )))
    )

    this.notify()
  }

  /**
   * Checks if the locally saved token data is valid
   * @return {Boolean} true/false - If the locally saved token is valid. Includes expiry check.
   */
  isLocalValid() {
    const td = this.getTokenData()

    if (!isObject(td)) return false
    if (!isString(td['access-token']) || td['access-token'].length <= 0) return false
    // eslint-disable-next-line max-len
    if (isString(td['access-token-expiry']) && td['access-token-expiry'].length <= 0) return false
    // eslint-disable-next-line max-len
    if (!isString(td['access-token-client']) || td['access-token-client'].length <= 0) return false
    // eslint-disable-next-line max-len
    if (!isString(td['access-token-uid']) || td['access-token-uid'].length <= 0) return false

    td['access-token-expiry'] = parseInt(td['access-token-expiry'])
    // eslint-disable-next-line max-len
    if (!isNumber(td['access-token-expiry']) || isNaN(td['access-token-expiry'])) return false
    if ((td['access-token-expiry'] * 1000) <= (new Date()).getTime()) return false

    return true
  }

  /**
   * Deleted the token data from localStorage which makes
   * the current session invalid.
   */
  invalidateToken() {
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({}))

    this.notify()
  }

  /**
   * Add a listener
   */
  addListener(func) {
    if (!isFunction(func)) return

    this._callbacks.push(func)
  }

  /**
   * Remove a listener
   */
  removeListener(func) {
    if (!isFunction(func)) return

    this._callbacks = without(this._callbacks, func)
  }

  /**
   * Notify all listeners about token data change
   */
  notify() {
    if (!isArray(this._callbacks)) return

    const isLocalValid = this.isLocalValid()

    for (const cb of this._callbacks) {
      if (isFunction(cb)) cb(isLocalValid)
    }
  }

  /**
   * Resets all listeners
   */
  reset() {
    this._callbacks = []
  }
}

export default AuthToken
