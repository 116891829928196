/* eslint-disable react/prop-types */

import { CaretDownFilled } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Styles from './index.module.sass'

const SimpleLanguageSelect = ({
  props,
}) => {
  const { t, i18n } = useTranslation()

  const langs = useMemo(() => ({
    en: { name: t('lang.english', 'English') },
    es: { name: t('lang.spanish', 'Español') },
    de: { name: t('lang.german', 'Deutsch') },
    da: { name: t('lang.danish', 'Dansk') },
  }), [t])

  const currentLang = useMemo(
    () => {
      const curLng = i18n?.language?.substr(0, 2)?.toLowerCase()
      if (['en', 'es', 'de', 'da'].indexOf(curLng) === -1) {
        return 'en'
      } else {
        return curLng
      }
    },
    [i18n.language],
  )

  const menu = (
    <Menu onClick={({ key }) => i18n.changeLanguage(key)}>
      {Object.keys(langs).map((lng) => (
        <Menu.Item
          key={lng}
          disabled={currentLang === lng}>
          {langs[lng]?.name || '--'}
        </Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Dropdown
      {...props}
      arrow
      overlay={menu}
    >
      <Button
        type="link" size="small" className={Styles.btnLink}
        onClick={(e) => e.preventDefault()}>
        {langs[currentLang]?.name || '--'} <CaretDownFilled className={Styles.svgArrow} />
      </Button>
    </Dropdown>
  )
}

export default SimpleLanguageSelect
