/* eslint-disable react/prop-types */

import { Space } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Styles from './LinkSpace.module.sass'

const LinkSpace = ({ children, mode }) => {
  const spaceClasses = classnames({
    [Styles.space]: true,
    [Styles.spaceSmall]: mode === 'small'
  })

  return (
    <Space
      align="center"
      direction="horizontal"
      split={(<span className={Styles.split}>•</span>)}
      className={spaceClasses}
    >
      {children}
    </Space>
  )
}

LinkSpace.propTypes = {
  //
  // Definition of normal or small, gray links
  mode: PropTypes.oneOf([
    'default',
    'small',
  ])
}

LinkSpace.defaultProps = {
  mode: 'default',
}

export default LinkSpace
