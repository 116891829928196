import './index.sass'
import './util/i18n'

import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter, RouterProvider, useLocation, useNavigationType,
  createRoutesFromChildren, matchRoutes
} from 'react-router-dom'
import Modal from 'react-modal'
import * as Sentry from '@sentry/react'
import Config from './lib/Config'
import isPresent from 'util/isPresent'
import reportWebVitals from './reportWebVitals'
import Routes from './Routes'

const enableSentry = isPresent(Config.SENTRY_DSN)
let sentrySampleRate = 0.0
switch (Config.SENTRY_ENV) {
  case 'production':
    sentrySampleRate = 1.0
    break
  case 'staging':
    sentrySampleRate = 0.1
    break
  default:
    sentrySampleRate = 0.0
    break
}

Sentry.init({
  dsn: Config.SENTRY_DSN,
  release: Config.RELEASE,
  environment: Config.SENTRY_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: sentrySampleRate,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
})

function createSentryRouter() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter(Routes)
}

const router = enableSentry ? createSentryRouter() : createBrowserRouter(Routes)
const rootElement = document.getElementById('root')

if (rootElement) {
  Modal.setAppElement(rootElement)

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    // @ts-ignore
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
