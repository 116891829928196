/* eslint-disable react/prop-types */

import ConfigContext from 'ConfigContext'
import FooterLogoUrl from 'assets/images/logo_black.svg'
import LogoUrl from 'assets/images/logo_green_black.svg'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import isAppOrigin from 'util/isAppOrigin'
import Styles from './Layout.module.sass'

const Layout = ({
  children,
  background,
}) => {
  const { reducedInterface, origin } = useContext(ConfigContext)

  const containerClasses = useMemo(() => {
    const classes = {
      [Styles.container]: true
    }

    if (isAppOrigin(origin)) {
      classes[Styles.containerMobile] = true
    }

    if (!isAppOrigin(origin) &&
        !reducedInterface) {
      classes[Styles.containerWeb] = true
      classes[Styles.containerResponsive] = background === 'responsive'
      classes[Styles.containerGreen] = background === 'green'
        || background === 'light-green'
      classes[Styles.containerLightGreen] = background === 'light-green'
      classes[Styles.containerMagenta] = background === 'magenta'
        || background === 'light-magenta'
      classes[Styles.containerLightMagenta] = background === 'light-magenta'
    }

    return classnames(classes)
  }, [background, origin, reducedInterface])

  return (
    <div className={containerClasses}>
      <div className={Styles.content}>
        <div className={Styles.logoContainer}>
          <img
            width="150"
            height="39"
            alt="VayaPin"
            src={LogoUrl}
            className={Styles.logo}
          />
        </div>
        <div className={Styles.body}>
          {children}
        </div>
        {!reducedInterface && (
          <>
            <div className={Styles.backgroundRight}></div>
            <div className={Styles.backgroundLeft}></div>
            <div className={Styles.footer}>
              <img
                width="50"
                height="13"
                alt="VayaPin"
                src={FooterLogoUrl}
                className={Styles.footerLogo}
              />
              <p>
                Vayapin © 2023
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Layout.propTypes = {
  //
  // Select background images
  background: PropTypes.oneOf([
    'green',
    'light-green',
    'magenta',
    'light-magenta',
    'responsive',
  ])
}

Layout.defaultProps = {
  background: 'responsive'
}

export default Layout
