/**
 * Application context referral information
 */

import { createContext } from 'react'

// Default context structure
export const DEFAULT_CONTEXT_DATA = {
  referralKey: null,
}

// Context
const ReferralContext = createContext(DEFAULT_CONTEXT_DATA)
ReferralContext.displayName = 'ReferralContext'

// export provider/consumer
export const Provider = ReferralContext.Provider
export const Consumer = ReferralContext.Consumer

// Export the context
export default ReferralContext
