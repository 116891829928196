/* eslint-disable react/prop-types */

import LinkSpace from 'atoms/LinkSpace'
import SimpleLanguageSelect from 'atoms/SimpleLanguageSelect'
import ConfigContext from 'ConfigContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Styles from './MetaLinks.module.sass'

const urls = {
  terms: 'https://www.vayapin.com/en/terms-of-use',
  privacy: 'https://www.vayapin.com/en/privacy-notes',
  imprint: 'https://www.vayapin.com/en/imprint',
}

const Link = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const MetaLinks = () => {
  const { reducedInterface } = useContext(ConfigContext)
  const { t } = useTranslation()
  if (reducedInterface) return null

  return (
    <div className={Styles.container}>
      <LinkSpace mode="small">
        <SimpleLanguageSelect />
        <Link href={urls.terms}>
          {t('meta.termsOfUse')}
        </Link>
        <Link href={urls.privacy}>
          {t('meta.privacyPolicy')}
        </Link>
        <Link href={urls.imprint}>
          {t('meta.imprint')}
        </Link>
      </LinkSpace>
    </div>
  )
}

export default MetaLinks
