/* eslint-disable react/prop-types */

import { DEFAULT_CONTEXT_DATA, Provider } from 'ConfigContext'
import { parse } from 'querystring'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import isAppOrigin from 'util/isAppOrigin'

const ConfigContextProvider = ({ children }) => {
  const location = useLocation()
  const params = parse(location?.search?.replace(/^\?/, ''))
  const { origin } = params

  //
  // Prepare context data
  const contextData = useMemo(() => ({
    ...DEFAULT_CONTEXT_DATA,
    origin,
    reducedInterface: isAppOrigin(origin)
  }), [origin])

  //
  // Render content
  return (
    <Provider value={contextData}>
      {children}
    </Provider>
  )
}

export default ConfigContextProvider
