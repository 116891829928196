class ConfigError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options)

    // necessary to assure "instanceof" checks
    Object.setPrototypeOf(this, ConfigError.prototype)

    if (Error.captureStackTrace) Error.captureStackTrace(this, ConfigError)

    return this
  }
}

export default ConfigError
