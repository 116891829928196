import LoadingIndicator from 'atoms/LoadingIndicator'
import StagingNote from 'atoms/StagingNote'
import AuthContextProvider from 'providers/AuthContextProvider'
import ConfigContextProvider from 'providers/ConfigContextProvider'
import ReferralCookieProvider from 'providers/ReferralCookieProvider'
import { FC, Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Outlet } from 'react-router-dom'

const RootScreen: FC<unknown> = () => (
  <CookiesProvider>
    <StagingNote position="topLeft" />
    <ReferralCookieProvider>
      <ConfigContextProvider>
        <AuthContextProvider>
          <Suspense fallback={<LoadingIndicator loading={true} />}>
            <Outlet />
          </Suspense>
        </AuthContextProvider>
      </ConfigContextProvider>
    </ReferralCookieProvider>
    <StagingNote position="bottomRight" />
  </CookiesProvider>
)

export default RootScreen
