import { redirect, RouteObject } from 'react-router-dom'
import AddSSOScreen from 'screens/AddSSOScreen'
import ConfirmationScreen from 'screens/ConfirmationScreen'
import FingerprintScreen from 'screens/FingerprintScreen'
import HomeScreen from 'screens/HomeScreen'
import NotFoundScreen from 'screens/NotFoundScreen'
import PasswordResetScreen from 'screens/PasswordResetScreen'
import PasswordScreen from 'screens/PasswordScreen'
import RevokeSSOScreen from 'screens/RevokeSSOScreen'
import RootScreen from 'screens/RootScreen'
import SignInScreen from 'screens/SignInScreen'
import SignOutScreen from 'screens/SignOutScreen'
import SignUpScreen from 'screens/SignUpScreen'
import AccountTermsScreen from 'screens/AccountTermsScreen'

const Routes: RouteObject[] = [
  {
    path: '/',
    element: <RootScreen />,
    errorElement: <NotFoundScreen />,
    children: [
      {
        path: '/',
        element: <HomeScreen />,
      },
      {
        path: 'sign-in',
        element: <SignInScreen />,
      },
      {
        path: 'sign-out',
        element: <SignOutScreen />,
      },
      {
        path: 'sign-up',
        element: <SignUpScreen />,
      },
      {
        path: 'password',
        element: <PasswordScreen />,
      },
      {
        path: 'password/reset',
        element: <PasswordResetScreen />,
      },
      {
        path: 'confirmation-success',
        element: <ConfirmationScreen />,
      },

      // signed in & account related
      {
        path: 'account/terms',
        element: <AccountTermsScreen />
      },

      // Redirection
      {
        path: 'r/cfp',
        element: <FingerprintScreen />,
      },

      // Private routes
      {
        path: 'add-sso',
        element: <AddSSOScreen />,
      },
      {
        path: 'revoke-sso',
        element: <RevokeSSOScreen />,
      },

      // Health check / status
      {
        path: 'health-check',
        element: <div>ok</div>,
      },

      // legacy
      {
        path: 'sign_in',
        action: () => redirect('/sign-in')
      },
      {
        path: 'sign_out',
        action: () => redirect('/sign-out'),
      },
      {
        path: 'sign_up',
        action: () => redirect('/sign-up'),
      },
      {
        path: 'auth/password',
        action: () => redirect('/password'),
      },
      {
        path: 'auth/confirmation',
        action: () => redirect('/confirmation-success'),
      },
    ]
  },
]

export default Routes
