import { ConfigError } from 'lib/errors'
import { isPresent } from 'lib/utils'

import {
  ADMIN_URL, API_PROXY_URL, API_URL, COOKIE_DOMAIN, COOKIE_SECURE,
  COOKIE_SHARED_DOMAIN, MY_URL, NODE_ENV, RELEASE, SENTRY_DSN, STAGING,
} from './Values'

function isStringPresent(str?: string | boolean): boolean {
  return isPresent(str) && str !== 'null' && str !== 'undefined'
}

if (!isStringPresent(NODE_ENV)) {
  throw new ConfigError('NODE_ENV missing')
}

if (!isStringPresent(RELEASE)) {
  throw new ConfigError('VITE_RELEASE missing')
}

if (!isStringPresent(ADMIN_URL)) {
  throw new ConfigError('VITE_ADMIN_URL missing')
}

if (!isStringPresent(API_URL)) {
  throw new ConfigError('VITE_API_URL missing')
}

if (!isStringPresent(API_PROXY_URL)) {
  throw new ConfigError('VITE_API_PROXY_URL missing')
}

if (!isStringPresent(COOKIE_DOMAIN)) {
  throw new ConfigError('VITE_COOKIE_DOMAIN missing')
}

if (!isStringPresent(COOKIE_SHARED_DOMAIN)) {
  throw new ConfigError('VITE_COOKIE_SHARED_DOMAIN missing')
}

if (!isStringPresent(MY_URL)) {
  throw new ConfigError('VITE_MY_URL missing')
}

if (!isStringPresent(SENTRY_DSN)) {
  throw new ConfigError('VITE_SENTRY_DSN missing')
}

const SENTRY_ENV = NODE_ENV === 'production' ?
  (STAGING ? 'staging' : 'production') :
  'development'

const Config = {
  NODE_ENV,
  RELEASE,
  STAGING,
  ADMIN_URL,
  API_URL,
  API_PROXY_URL,
  COOKIE_SECURE,
  COOKIE_DOMAIN,
  COOKIE_SHARED_DOMAIN,
  SENTRY_ENV,
  MY_URL,
  SENTRY_DSN,
}

console.log('VITE_STAGING', import.meta.env.VITE_STAGING)
if (STAGING) {
  console.log('##########################################')
  console.log('# Staging                                #')
  console.log('##########################################')
}

export default Config
