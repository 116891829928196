import classnames from 'classnames'
import ConfigContext from 'ConfigContext'
import { ReactNode, useContext, useMemo } from 'react'
import isAppOrigin from 'util/isAppOrigin'

import Styles from './ContentCard.module.sass'

export interface ContentCardProps {
  children: ReactNode;
}

const ContentCard = ({ children }: ContentCardProps) => {
  const { origin } = useContext(ConfigContext)

  const classes = useMemo(() => classnames({
    [Styles.card]: true,
    [Styles.cardMobile]: isAppOrigin(origin),
    [Styles.cardWeb]: !isAppOrigin(origin),
  }), [origin])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default ContentCard
