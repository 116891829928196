/**
 * Context for auth state
 */

import { createContext } from 'react'

// Default context structure
export const DEFAULT_CONTEXT_DATA = {
  loaded: false,
  loading: true,
  signedIn: false,
  userData: null,
  validate: async () => {},
}

// Context
const AuthContext = createContext(DEFAULT_CONTEXT_DATA)
AuthContext.displayName = 'AuthContext'

// export provider/consumer
export const Provider = AuthContext.Provider
export const Consumer = AuthContext.Consumer

// Export the context
export default AuthContext
