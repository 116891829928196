function isBooleanTrue(value: string): boolean {
  return value === 'true' || `${JSON.stringify(value || '')}` === 'true'
}

export const NODE_ENV = import.meta.env.PROD ? 'production' : 'development'
export const STAGING = isBooleanTrue(import.meta.env.VITE_STAGING as string)
export const RELEASE = import.meta.env.VITE_RELEASE as string
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL as string
export const API_URL = import.meta.env.VITE_API_URL as string
export const API_PROXY_URL = import.meta.env.VITE_API_PROXY_URL as string
export const COOKIE_SECURE = isBooleanTrue(import.meta.env.VITE_COOKIE_SECURE as string)
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN as string
export const COOKIE_SHARED_DOMAIN = import.meta.env.VITE_COOKIE_SHARED_DOMAIN as string
export const MY_URL = import.meta.env.VITE_MY_URL as string
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string

