import { Button, Result } from 'antd'
import ContentCard from 'layout/ContentCard'
import Layout from 'layout/Layout'
import MetaLinks from 'molecules/MetaLinks'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFoundScreen = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onClick = useCallback(() => navigate('/'), [navigate])

  return (
    <>
      <Helmet>
        <title>{t('notFound.title')} | 404</title>
      </Helmet>
      <Layout>
        <ContentCard>
          <Result
            status="404"
            title="404"
            subTitle={t('notFound.text')}
            extra={
              <Button type="primary" onClick={onClick}>
                {t('notFound.home')}
              </Button>
            }
          />
        </ContentCard>
        <MetaLinks />
      </Layout>
    </>
  )
}

export default NotFoundScreen
