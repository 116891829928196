import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import Config from 'lib/Config'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'de', 'da'],
    defaultNS: 'common',
    debug: Config.NODE_ENV !== 'production',
    saveMissing: true,
    saveMissingTo: 'all',
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      caches: ['localStorage', 'cookie'],
      cookieDomain: Config.COOKIE_SHARED_DOMAIN,
      lookupQuerystring: 'lang',
    },
    // learn more: https://github.com/i18next/i18next-xhr-backend
    //backend:{}
  })
export default i18n
