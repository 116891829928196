import Config from 'lib/Config'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Styles from './StagingNote.module.sass'

const StagingNote = ({ position }) => {
  const className = useMemo(() => position === 'topLeft'
    ? [Styles.container, Styles.containerTopLeft].join(' ')
    : [Styles.container, Styles.containerBottomRight].join(' ')
  ,[position])

  if (Config.STAGING !== true) return null

  return <div className={className}></div>
}

StagingNote.propTypes = {
  position: PropTypes.oneOf([
    'topLeft',
    'bottomRight'
  ]).isRequired,
}

export default StagingNote
