/* eslint-disable react/prop-types */

import { Provider } from 'contexts/ReferralContext'
import Config from 'lib/Config'
import { parse } from 'querystring'
import { useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import isPresent from 'util/isPresent'

const REFERRAL_COOKIE_NAME = 'vp-ref'

const ReferralCookieProvider = ({ children }) => {
  const location = useLocation()
  const [cookies, setCookie] = useCookies([REFERRAL_COOKIE_NAME])
  const referralKey = cookies[REFERRAL_COOKIE_NAME]
  const params = parse(location?.search?.replace(/^\?/, ''))

  // context data
  const conextData = useMemo(() => ({
    referralKey,
  }), [referralKey])

  // set cookie
  useEffect(() => {
    if (!isPresent(params?.referral)) return

    setCookie(
      REFERRAL_COOKIE_NAME,
      params.referral,
      {
        path: '/',
        domain: Config.COOKIE_SHARED_DOMAIN,
        secure: Config.COOKIE_SECURE,
        httpOnly: false,
      }
    )
  }, [params, setCookie])

  return (
    <Provider value={conextData}>
      {children}
    </Provider>
  )
}

export default ReferralCookieProvider
