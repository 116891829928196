import LoadingIndicator from 'atoms/LoadingIndicator'
import ContentCard from 'layout/ContentCard'
import Layout from 'layout/Layout'

const LazyLoadingScreen = () => (
  <Layout>
    <ContentCard>
      <LoadingIndicator loading={true} />
    </ContentCard>
  </Layout>
)

export default LazyLoadingScreen
