import PropTypes from 'prop-types'
import PuffLoader from 'react-spinners/PuffLoader'
import Styles from './LoadingIndicator.module.sass'

const LoadingIndicator = ({
  loading,
  size,
}) => loading ? (
  <div className={Styles.container}>
    <PuffLoader
      color='#19bd24'
      loading={loading}
      size={size === 'small' ? 30 : 60}
    />
  </div>
) : null

LoadingIndicator.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf([
    'default',
    'small',
  ])
}

LoadingIndicator.defaultProps = {
  loading: false,
  size: 'default',
}

export default LoadingIndicator
