/* eslint-disable react/prop-types */

import { Provider } from 'AuthContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Api from 'util/Api'
import AuthToken from 'util/AuthToken'

const AuthContextProvider = ({
  children,
}) => {
  const [signedIn, setSignedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [userData, setUserData] = useState({})

  const validate = useCallback(async () => {
    setLoading(true)
    setUserData(await Api.get().callValidateToken())
    setSignedIn(AuthToken.get().isLocalValid())
    setLoading(false)
    setLoaded(true)
  }, [setLoading, setLoaded, setUserData, setSignedIn])

  //
  // Check sign in state
  useEffect(() => {
    const updateSignedIn = () => {
      setSignedIn(AuthToken.get().isLocalValid())
    }
    validate()

    AuthToken.get().addListener(updateSignedIn)

    return () => AuthToken.get().removeListener(updateSignedIn)
  }, [validate, setSignedIn])

  //
  // Prepare data
  const contextData = useMemo(() => ({
    loaded,
    loading,
    signedIn,
    userData,
    validate,
  }), [
    loaded,
    loading,
    signedIn,
    userData,
    validate
  ])

  //
  // Render
  return (
    <Provider value={contextData}>
      {children}
    </Provider>
  )
}

export default AuthContextProvider
