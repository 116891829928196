import axios from 'axios'
import merge from 'deepmerge'
import Config from 'lib/Config'
import _ from 'lodash'
import AuthToken from 'util/AuthToken'
import I18next from 'util/i18n'

let _instance = null

export default class Api {

  /**
   * The singleton Auth instance.
   * @static
   * @return {Auth} Instance
   */
  static get() {
    if (!_instance) { _instance = new this() }
    return _instance
  }

  /**
   * Reset singleton instance. Auth.get() will return a new instance next time called.
   * @static
   */
  static reset() {
    _instance = null
  }

  /**
   * Constructor - create axios instance
   */
  constructor() {
    this.instance = axios.create()
  }

  async callValidateToken() {
    let response = await this.call({
      method: 'GET',
      url:    '/api_auth/t/validate_token',
    })

    if (response instanceof Error) response = response.response

    if (response
        && response.status
        && /^4.*/.test(`${response.status}`)) {
      AuthToken.get().invalidateToken()
    }

    return response?.data?.data
  }

  async callInternalApps() {
    return await this.call({
      method: 'GET',
      url: '/api_auth/internal/apps',
    })
  }

  async callInternalUpdateUser(data) {
    return await this.call({
      method: 'put',
      url: '/api_auth/internal/user',
      data
    })
  }

  async callInternalRevokeSSO(provider) {
    return await this.call({
      method: 'post',
      url: '/api_auth/internal/revoke_oauth_access',
      data: { provider }
    })
  }

  async call(axiosConfig = {}) {
    axiosConfig = this.prepareAxiosConfig(merge({}, axiosConfig))

    try {
      const response = await this.instance.request(axiosConfig)

      if (response instanceof Error) return this.handleError(response)

      if (response && response.status && /^2.*/.test(`${response.status}`)) {
        AuthToken.get().updateTokenData(response.headers)
      }

      return response
    } catch (e) {
      return this.handleError(e)
    }
  }

  getApiHost() {
    return Config.API_URL.replace(/\/$/, '')
  }

  prepareAxiosConfig(axiosConfig = {}) {
    // baseURL
    if (!axiosConfig.baseURL) axiosConfig.baseURL = this.getApiHost()

    // basic headers
    if (!_.isObject(axiosConfig.headers)) axiosConfig.headers = {}
    if (!axiosConfig.headers['Accept']) axiosConfig.headers['Accept'] = 'application/json'
    if (I18next?.language) {
      axiosConfig.headers['language'] = I18next.language // legacy
      axiosConfig.headers['Accept-Language'] = I18next.language
    }

    // prevent redirect following
    if (!axiosConfig.maxRedirects) axiosConfig.maxRedirects = 0

    // set token data
    const tokenData = AuthToken.get().getTokenData()

    if (_.isObject(tokenData)) {
      axiosConfig.headers['access-token'] = tokenData['access-token']
      axiosConfig.headers['access-token-client'] = tokenData['access-token-client']
      axiosConfig.headers['access-token-uid'] = tokenData['access-token-uid']
    }

    // return adjusted config
    return axiosConfig
  }

  handleError(e) {
    if (e.response && e.response.status &&
        _.includes([401, '401'], e.response.status)) {
      return e
    } else {
      throw e
    }
  }
}
